.modal {
	width: 40%;
    height:40%;
    border: 1px solid black;
    position: fixed;
    margin: auto;
    inset: 0;
	z-index: 99;
	color: red;
	background-color: rgba(0, 0, 0, 0.925);
	display: flex;
	align-items: center;
	justify-content: center;
}
.modal button {
	margin-left: 10px;
}