/*
Template Name: FoodBoard
Template URL: https://ultimatewebsolutions.net/foodboard/
Author: Ultimate Websolutions
Author URL: https://themeforest.net/user/ultimatewebsolutions/

[Table of contents]

01. PRELOADER
02. GENERAL
03. MODAL ALERT
04. MODAL DIALOG
05. CART MESSAGES
06. HEADER
07. SUB HEAEDR
08. FOOTER
09. HERO
10. SERVICES
11. BANNER
12. FAQ
13. MAP
14. CONTAINERS
15. ORDER ITEMS
16. FORM ELEMENTS
17. ORDER SUMMARY
18. CONFIRMATION
19. BACK TO TOP
20. RESPONSIVE
/*---------------------
  Sign In
-----------------------*/
.top-nav {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	color: #FFF;
	height: 50px;
	padding: 1em;
  }
  
  .menu {
	display: flex;
	flex-direction: row;
	list-style-type: none;
	margin: 0;
	padding: 0;
  }
  
  .menu > a {
	margin: 0 1rem;
	overflow: hidden;
  }
  
  .menu-button-container {
	display: none;
	height: 100%;
	width: 30px;
	cursor: pointer;
	flex-direction: column;
	justify-content: center;
	align-items: center;
  }
  
  #menu-toggle {
	display: none;
  }
  
  .menu-button,
  .menu-button::before,
  .menu-button::after {
	display: block;
	background-color: #f25c04;
	position: absolute;
	height: 4px;
	width: 30px;
	transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
	border-radius: 2px;
  }
  
  .menu-button::before {
	content: '';
	margin-top: -8px;
  }
  
  .menu-button::after {
	content: '';
	margin-top: 8px;
  }
  
  #menu-toggle:checked + .menu-button-container .menu-button::before {
	margin-top: 0px;
	transform: rotate(405deg);
  }
  
  #menu-toggle:checked + .menu-button-container .menu-button {
	background: rgba(255, 255, 255, 0);
  }
  
  #menu-toggle:checked + .menu-button-container .menu-button::after {
	margin-top: 0px;
	transform: rotate(-405deg);
  }
  
  @media (max-width: 900px) {
	.menu-button-container {
	  display: flex;
	}
	#logo {
		left: 5px !important;
	}
	.logoDiv {
		display: flex;
		justify-content: center;
		margin-top: 4px;
	}
	.menu {
	  position: absolute;
	  top: 0;
	  margin-top: 50px;
	  left: 0;
	  flex-direction: column;
	  width: 100%;
	  justify-content: center;
	  align-items: center;
	}
	#menu-toggle ~ .menu a {
	  height: 0;
	  margin: 0;
	  padding: 0;
	  border: 0;
	  transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
	}
	#menu-toggle:checked ~ .menu a {
	  border: 1px solid #333;
	  height: 2.5em;
	  padding: 0.5em;
	  transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
	}
	.menu > a {
	  display: flex;
	  justify-content: center;
	  margin: 0;
	  padding: 0.5em 0;
	  width: 100%;
	  color: white;
	  background-color: #222;
	}
	.menu > a:not(:last-child) {
	  border-bottom: 1px solid #444;
	}
  }
  @media (max-width: 400px) {
	.hero-home.bg-mockup {
		padding-top: 30px;
	}
  }
  @media (max-width: 300px) {
	.hero-home.bg-mockup {
		padding-top: 50px;
	}
  }


#thankYouMessage {
	font-size: 18px;
}
.toProceed,
.checkout {
	font-size: 20px;
}
.toProceed .cta-button {
	margin: 0px 15px 35px 15px;
}
.toProceed .icon {
	color: #f25c04;
}

.boxMainWithImage {
	padding: 10px;
	height: 35rem;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 5rem;
}
.boxMainWithImage p {
	font-size: 20px;
}
.boxMainWithImage h3 {
	font-size: 30px;
}
.boxMainWithImage .box-image {
	flex: 0 0 33%;
	max-width: 33%;
}
.boxMainWithImage .box-image img {
	width: 100%;
	height: auto;
	object-fit: cover;
}
.boxMainWithImage .box-content {
	flex: 1;
	padding-left: 20px;
	text-align: left;
}
@media (max-width: 1100px) {
	.boxMainWithImage {
		flex-direction: column;
		height: fit-content;
	}

	.boxMainWithImage .box-image,
	.boxMainWithImage .box-content {
		flex: 0 0 70%;
		max-width: 100%;
	}
	.box-image {
		height: 100px;
	}

	.boxMainWithImage .box-content {
		padding-left: 0;
		text-align: center;
	}
}
.whatsInside .btn {
	margin-top: 25px;
}
.whatsInside span {
	font-size: 23px;
	font-weight: 500;
}
.whatsInsideList span {
	font-size: 20px;
}
.whatsInside .icon {
	font-size: 30px;
	color: #f25c04;
}
.whatsInsideList .icon {
	font-size: 23px;
}
.pricing {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	width: 100%;
	margin: 0 auto;
	position: relative;
}
.row {
	margin: 0;
}
.pricing__item {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	text-align: center;
	flex: 0 1 360px;
}

.pricing__feature-list {
	text-align: left;
}

.pricing__action {
	color: inherit;
	border: none;
	background: none;
}

.pricing__action:focus {
	outline: none;
}

.pricing--pema .pricing__feature-list i {
	margin-right: 8px;
}
.themeslr-pricing-image--holder.img-circle img {
	border-radius: 50%;
	box-shadow: 0 0 10px rgb(0 0 0 / 20%);
}
/* Pricing Table Style3 Pema */
.pricing--pema .pricing__item {
	padding: 2em 2em;
	border: 1px solid #f25c04;
	cursor: default;
}

@media screen and (min-width: 66.25em) {
	.pricing--pema .pricing__item--featured {
		z-index: 10;
		font-size: 1.15em;
	}
}

.pricing__title span {
	background: #f8f8f8;
	padding: 8px 20px;
	font-weight: 500;
	text-transform: uppercase;
}
.pricing--pema .pricing__title {
	color: #f25c04;
	font-size: 15px;
	font-weight: 300;
	position: absolute;
	top: -12px;
	width: 100%;
	left: 0;
}
.pricing--pema .icon {
	display: inline-block;
	min-width: 2em;
	color: #8a9790;
	vertical-align: middle;
}
.pricing--pema .pricing__price {
	font-family: "Montserrat";
	font-size: 90px;
	font-weight: 700;
	position: relative;
	z-index: 100;
}

.pricing--pema .pricing__currency {
	font-size: 0.5em;
	padding-right: 10px;
	vertical-align: super;
}
.pricing__bubble_content {
	z-index: 2;
	position: relative;
	width: 100%;
	display: block;
	right: 5px;
}
.pricing--pema .pricing__period svg {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 1;
}
.pricing--pema .pricing__period {
	display: inline-block;
	font-size: 14px;
	font-weight: 400;
	text-align: center;
	position: absolute;
	right: 0;
	top: 0.2px;
	height: 80px;
	width: 75px;
	background-size: 80px;
	padding-left: 15px;
	padding-top: 15px;
	line-height: normal;
}
.pricing--pema .pricing__period strong {
	font-size: 17px;
	color: #454545;
}
.pricing--pema .pricing__sentence {
	opacity: 0.6;
}
.pricing--pema .pricing__sentence {
	font-weight: bold;
	margin-bottom: 0px;
	color: #ffffff;
	padding: 0 0 0.5em;
}
.themeslr-pricing-image--holder {
	margin-top: 30px;
}
.themeslr-pricing-table .themeslr-pricing-image--holder img {
	height: 150px;
}
.themeslr-pricing-table .themeslr-pricing-button--holder .button {
	color: #fff;
	width: 20%;
}
.themeslr-pricing-table .themeslr-pricing-button--holder {
	position: absolute;
	bottom: -17px;
	left: 0;
	width: 100%;
}
.themeslr-pricing-table .themeslr-pricing-button--holder a {
	width: 45%;
}
.pricing--pema .pricing__feature-list {
	font-size: 0.95em;
	margin: 0;
	padding: 1.5em 0.5em 2.5em;
	list-style: none;
}
.pricing--pema .pricing__feature {
	font-size: 16px;
	font-weight: 300;
	padding: 5px 0;
	text-align: center;
	color: #606060;
	border-bottom: 1px solid #e8e8e8;
}
.pricing--pema .pricing__feature:last-child {
	border-bottom: 0 none;
}
.pricing--pema .pricing__action {
	font-weight: bold;
	margin-top: auto;
	padding: 1em 2em;
	color: #fff;
	border-radius: 5px;
	-webkit-transition: background-color 0.3s;
	transition: background-color 0.3s;
}

#themeslr_pricing--64869b1a03273 .pricing--pema .pricing__sentence {
	color: #222222;
}

#themeslr_pricing--64869b1a03273 .pricing--pema .pricing__price {
	color: #222222;
}

#themeslr_pricing--64869b1a03273 .pricing--pema .button {
	background-color: #f25c04;
}

#themeslr_pricing--64869b1a03273 .pricing--pema .button:hover,
#themeslr_pricing--64869b1a03273 .pricing--pema .button:focus {
	background-color: #f26226;
}

#themeslr_pricing--64869b1a03273 .pricing--pema .pricing__item {
	background: #ffffff none repeat scroll 0 0;
	transition: all 300ms ease-in-out 0ms;
	-ms-transformtransition: all 300ms ease-in-out 0ms;
	-webkit-transformtransition: all 300ms ease-in-out 0ms;
}

#themeslr_pricing--64869b1a03273 .pricing--pema .pricing__item:hover {
	background: #ffffff none repeat scroll 0 0;
	transition: all 300ms ease-in-out 0ms;
	-ms-transformtransition: all 300ms ease-in-out 0ms;
	-webkit-transformtransition: all 300ms ease-in-out 0ms;
}

/* */
.fixed-position {
	overflow: hidden;
}
.signin__form__signup__social {
	display: flex;
	justify-content: space-around;
	align-items: center;
}
.signin {
	background: rgba(0, 0, 0, 0.85);
	position: fixed;
	height: 100%;
	width: 100%;
	left: 0;
	top: 0;
	z-index: 9;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	overflow-y: auto;
}
.site-btn {
	color: #ffffff;
	font-weight: 800;
	text-transform: uppercase;
	display: inline-block;
	padding: 8px 26px 8px;
	background: #f25c04;
	border: none;
}
.close-btn {
	width: 50%;
	margin-left: 25%;
	margin-bottom: 10px;
}

.signin__content {
	width: 100%;
	text-align: center;
}

.signin__content p {
	font-size: 16px;
	color: #ffffff;
	letter-spacing: 1px;
	margin-bottom: 25px;
}

.signin__form {
	background: #ffffff;
}

.signin__form .nav-tabs {
	border-bottom: none;
}

.signin__form .nav-item {
	width: 50%;
	float: left;
	margin-bottom: 0;
}

.signin__form .nav-item .nav-link {
	font-size: 16px;
	color: #111111;
	text-transform: uppercase;
	border: none;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	letter-spacing: 1px;
}
.nav-link {
	width: 100%;
}

.signin__form .nav-item .nav-link.active {
	color: #f25c04;
}

.signin__form__text {
	padding: 50px;
	padding-top: 38px;
}

.signin__form__text p {
	font-size: 15px;
	color: #111111;
	margin-bottom: 15px;
}

.signin__form__text form input {
	height: 46px;
	width: 100%;
	background: transparent;
	border: 1px solid #e1e1e1;
	font-size: 14px;
	color: #444444;
	padding-left: 20px;
	margin-bottom: 20px;
}

.signin__form__text form input:last-child {
	color: #444444;
}

.signin__form__text form label {
	position: relative;
	font-size: 15px;
	color: #444444;
	padding-left: 20px;
	margin-bottom: 30px;
	display: block;
	cursor: pointer;
	margin-left: 20px;
	text-align: left;
	margin-bottom: 20px;
}

.signin__form__text form label input:checked ~ .checkmark {
	color: #f25c04;
}

.signin__form__text form label .checkmark {
	position: absolute;
	left: 0;
	top: 6px;
	height: 10px;
	width: 10px;
	border: 1px solid #e1e1e1;
	border-radius: 2px;
}

.signin__form__text form label input {
	position: absolute;
	visibility: hidden;
	display: none;
}

.signin__form__text form label input:checked ~ span {
	background: #f25c04;
	border-color: #f25c04;
}

.signin__form__signup__social {
	margin-bottom: 20px;
}

.signin__form__signup__social button {
	display: inline-block;
	font-size: 16px;
	color: #ffffff;
	height: 46px;
	width: 46px;
	background: #f2f2f2;
	border-radius: 50%;
	line-height: 46px;
	text-align: center;
	margin-right: 6px;
}

.signin__form__signup__social button.facebook {
	background: #1369c2;
}

.signin__form__signup__social button.twitter {
	background: #24c9ec;
}

.signin__form__signup__social button.google {
	background: #d41623;
}

.signin__form__signup__social button:last-child {
	margin-right: 0;
}
.signin__form__signup__social i {
	font-size: 140%;
}

.divide {
	font-size: 15px;
	color: #111111;
	margin-bottom: 15px;
}
/* Preloader
==================================== */

#preloader {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	bottom: 0;
	background-color: transparent;
	z-index: 3;
}

[data-loader="circle-side"] {
	position: absolute;
	width: 50px;
	height: 50px;
	top: 50%;
	left: 50%;
	margin-left: -25px;
	margin-top: -25px;
	-webkit-animation: circle infinite 0.95s linear;
	-moz-animation: circle infinite 0.95s linear;
	-o-animation: circle infinite 0.95s linear;
	animation: circle infinite 0.95s linear;
	border: 2px solid #f25c04;
	border-top-color: rgba(0, 0, 0, 0.2);
	border-right-color: rgba(0, 0, 0, 0.2);
	border-bottom-color: rgba(0, 0, 0, 0.2);
	-webkit-border-radius: 100%;
	-moz-border-radius: 100%;
	-ms-border-radius: 100%;
	border-radius: 100%;
}

@-webkit-keyframes circle {
	0% {
		-webkit-transform: rotate(0);
		-moz-transform: rotate(0);
		-ms-transform: rotate(0);
		-o-transform: rotate(0);
		transform: rotate(0);
	}
	100% {
		-webkit-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@-moz-keyframes circle {
	0% {
		-webkit-transform: rotate(0);
		-moz-transform: rotate(0);
		-ms-transform: rotate(0);
		-o-transform: rotate(0);
		transform: rotate(0);
	}
	100% {
		-webkit-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@-o-keyframes circle {
	0% {
		-webkit-transform: rotate(0);
		-moz-transform: rotate(0);
		-ms-transform: rotate(0);
		-o-transform: rotate(0);
		transform: rotate(0);
	}
	100% {
		-webkit-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes circle {
	0% {
		-webkit-transform: rotate(0);
		-moz-transform: rotate(0);
		-ms-transform: rotate(0);
		-o-transform: rotate(0);
		transform: rotate(0);
	}
	100% {
		-webkit-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

/* General
==================================== */
html {
	scroll-behavior: smooth;
}

/* Plans info 
==================================== */
.ribbon-discount-main {
	position: absolute;
	right: 0;
	top: 0;
	z-index: 2;
	overflow: hidden;
	width: 10rem;
	height: 10rem;
	text-align: right;
}
.original-price {
	text-decoration: line-through;
	padding-right: 5px;
	font-size: 0.9em;
}
.discounted-price {
	font-size: 1.2em;
	padding-left: 5px;
	color: #f25c04;
}
.free-shipping {
	color: #f25c04;
	font-weight: bold;
	margin-top: 10px;
	margin-bottom: 0;
}
.planInfo {
	margin-bottom: 1.5em;
}
.button-back,
.btn-secondary {
	margin: 0 15px 15px;
}
.cta-button {
	margin: 10px 2px;
}
.cta-button,
.button-back {
	background-color: #f25c04; /* Change to your preferred color */
	color: white;
	border: none;
	padding: 10px 20px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 16px;
	cursor: pointer;
}
.getStartedParagraph {
	margin-top: 5px;
}
/* Plans info end
==================================== */
html * {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

html ::-webkit-scrollbar {
	width: 10px;
	height: 10px;
}

html ::-webkit-scrollbar-button {
	display: none;
}

html ::-webkit-scrollbar-track-piece {
	background-color: #e9e9e9;
}

html ::-webkit-scrollbar-thumb {
	background-color: #f25c04;
	border-radius: none;
}

html ::-webkit-scrollbar-corner {
	background-color: #e9e9e9;
}

body {
	background: #f8f8f8;
	font-size: 14px;
	font-family: "Jost", sans-serif;
	color: #555;
}

main {
	background: #f8f8f8;
	position: relative;
	z-index: 1;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	color: #333;
}

h1,
h2 {
	font-weight: 700;
}

hr {
	margin: 15px 0 30px 0;
	border-color: #ededed;
}

p {
	margin-top: 30px;
	margin-bottom: 30px;
}

a {
	color: #f25c04;
	text-decoration: none;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-webkit-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	outline: none;
}

a:hover,
a:focus,
a:visited {
	color: #333;
	text-decoration: none;
	outline: none;
}

ul,
ol {
	list-style: none;
	margin: 0 0 25px 0;
	padding: 0;
}

p.lead {
	font-weight: 400;
	font-size: 20px;
	color: #555;
}

strong {
	font-weight: 600;
}

label {
	color: #555;
	font-size: 13px;
	font-weight: 500;
	margin: 0;
}

button:focus {
	outline: none;
}

.pl-15 {
	padding-left: 15px;
}

.pr-10 {
	padding-right: 10px;
}

.pr-15 {
	padding-right: 15px;
}

/* Content */

.modal-popup .content {
	padding: 20px;
}

.modal-popup .content {
	padding: 15px;
}

.modal-popup .content h5 {
	font-size: 14px;
	margin-bottom: 15px;
}

.modal-popup .content ul {
	padding: 0;
	margin: 0 0 20px 0;
	list-style: none;
}

.cbx span.option-price {
	float: right;
	font-size: 13px;
	padding-top: 2px;
}

/* Header
==================================== */

header {
	background-color: #fff;
	min-height: 55px;
	padding: 10px 0;
	border-bottom: 1px solid transparent;
}

header.main-header,
header.main-header-2 {
	width: 100%;
	z-index: 2;
	position: relative;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-webkit-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}

header.main-header.sticky {
	border-bottom: 1px solid #fff;
	position: fixed;
	left: 0;
	top: 0;
	z-index: 2;
}

header.main-header.active {
	border-bottom: 1px solid #ddd;
}

header.static {
	position: static;
	border-bottom: 1px solid #ddd;
	z-index: 2;
	position: relative;
}

/* Header Menu Color */
.main-menu {
	position:relative;
	z-index: 2;
	width: auto;
	top: 11px;
	float: right;
	transition: all 0.2s ease-in-out;
	color: #fff;
}
.navLink {
	color: #333;
	padding: 0 8px 17px 8px;
	font-size: 0.875rem;
	font-weight: 500;
}
.main-menu ul,
.main-menu ul li {
	position: relative;
	display: inline-block;
	margin: 0;
	padding: 0;
}
.main-menu > ul > li span:hover > a {
	color: #f25c04;
}

.main-menu ul ul {
	border-top: 2px solid #f25c04;
}

.main-menu ul ul li:hover > a {
	color: #f25c04;
}

.main-menu ul ul li span > a:hover {
	color: #f25c04;
}

/* Header Menu Chevron Down */

.main-menu a i {
	font-size: 9px;
	margin-left: 2px;
}

/* Header Sub Menu Icon */

.main-menu ul ul li span > a:after {
	font-family: "FontAwesome";
	font-weight: 500;
	content: "\f054";
	float: right;
	font-size: 9px;
	margin-top: 3px;
}

/* Header Icons */

ul#menuIcons {
	float: right;
	position: relative;
	top: 3px;
	margin: -1px 0 0 0;
}

ul#menuIcons li {
	display: inline-block;
	position: relative;
	padding: 0;
	margin-left: 15px;
}

ul#menuIcons li i {
	font-size: 25px;
}

ul#menuIcons li a {
	color: #f25c04;
}

ul#menuIcons li a:hover {
	color: #333;
}

/* Logo */

#logo h1 {
	margin: 0;
	padding: 0;
	line-height: 1;
}

#logo h1 a {
	width: 200px;
	height: 35px;
	display: block;
	background: url(../img/logo.svg) no-repeat 0 0;
	background-size: 200px 40px;
	text-indent: -9999px;
}

/* Sticky Nav */

.sticky-nav {
	width: 100%;
	background-color: #fff;
	padding: 15px 0;
	position: relative;
}

.sticky-nav span {
	position: absolute;
	height: 7px;
	width: 100%;
	left: 0;
	bottom: 0;
	box-shadow: 0 2px 2px 0 rgb(9 30 66 / 13%);
}

.sticky-nav ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

.sticky-nav ul li {
	display: inline-block;
	margin-right: 15px;
}

.sticky-nav a.list-group-item {
	color: #333;
	-webkit-border-radius: 25px;
	-moz-border-radius: 25px;
	-ms-border-radius: 25px;
	border-radius: 25px;
	background-color: transparent;
	padding: 6px 15px;
	font-weight: 500;
	border: 1px solid #ccc;
	text-align: center;
}

.sticky-nav a.list-group-item.active {
	color: #fff;
	background-color: #f25c04;
	border-color: #f25c04;
}

.sticky-nav a:before {
	content: "";
	position: absolute;
	bottom: -1px;
	left: 0;
	width: 0;
	height: 2px;
	background-color: #f25c04;
}

.sticky-nav a.active,
.sticky-nav a:hover {
	color: #f25c04;
	border-color: #f25c04;
}

.sticky-nav a i {
	margin-right: 8px;
}

.sticky-nav.is_stuck {
	position: fixed;
	width: 100%;
	left: 0;
	top: 0;
	border-top: none;
	z-index: 3;
}

/* Sub Header
==================================== */

.sub-header {
	background-color: #121921;
	padding: 15px 0;
	margin-top: 59px;
}

.sub-header h1 {
	color: #fff;
	margin: 0;
	font-size: 18px;
	font-weight: 500;
}

/* Footer
==================================== */

footer.main-footer h5 {
	color: #fff;
}

footer.main-footer {
	padding-top: 90px;
	padding-bottom: 90px;
	color: #bcbcbc;
	background-color: #121921;
	border-top: 1px solid #ddd;
}

footer.main-footer ul.nav-links li i {
	font-size: 13px;
}

.footer-heading {
	margin-bottom: 1.25rem;
	font-weight: 600;
}

a.footer-link {
	color: #bcbcbc;
	font-size: 14px;
	font-weight: 400;
	line-height: 1.8;
}

.footer-link:hover {
	color: #fff;
}

.contact-links i {
	color: #f25c04;
	margin-right: 10px;
	font-size: 18px;
	vertical-align: middle;
}

a.social-link {
	color: #fff;
	opacity: 0.6;
	font-size: 20px;
	font-weight: 400;
	margin-right: 10px;
}

a.social-link:visited {
	color: #fff;
}

.social-links li {
	display: inline-block;
}

footer #copy {
	text-align: right;
	font-size: 13px;
	color: #bcbcbc;
}

footer hr {
	border-top: 2px dotted rgba(242, 246, 247, 0.3);
	margin-top: 5px;
}

/* Sub Footer Links */

ul#subFooterLinks {
	margin: 0;
	font-size: 13px;
	color: #bcbcbc;
}

ul#subFooterLinks li {
	display: inline-block;
	margin-right: 15px;
}

ul#subFooterLinks li:first-child {
	margin-right: 20px;
}

ul#subFooterLinks li:last-child:after {
	content: "";
}

ul#subFooterLinks li a {
	color: #bcbcbc;
}

ul#subFooterLinks li a:hover {
	text-decoration: underline;
}

ul#subFooterLinks li:after {
	content: "|";
	font-weight: 300;
	position: relative;
	left: 10px;
}

ul#subFooterLinks i {
	color: #f25c04;
}

/* Sub Footer Links */

ul#subFooterLinks {
	margin: 0;
	font-size: 13px;
	color: #999;
}

ul#subFooterLinks li {
	display: inline-block;
	margin-right: 15px;
}

ul#subFooterLinks li:first-child {
	margin-right: 20px;
}

ul#subFooterLinks li:last-child:after {
	content: "";
}

ul#subFooterLinks li a {
	color: #999;
}

ul#subFooterLinks li a:hover {
	text-decoration: underline;
}

ul#subFooterLinks li:after {
	content: "|";
	font-weight: 300;
	position: relative;
	left: 10px;
}

ul#subFooterLinks i {
	color: #f25c04;
}

/* Hero
==================================== */

.hero-home {
	height: 500px;
	width: 100%;
	display: table;
}

.hero-home.bg-mockup {
	background: #f25c04 url(../img/bg/bg-old-2.webp) no-repeat center bottom;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}
.hero-home h1, .hero-home p {
	font-family: "FontAwesome";
}
.hero-home .content {
	display: table-cell;
	padding: 0;
	vertical-align: middle;
	text-align: center;
	font-size: 21px;
	color: #fff;
	text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
	background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.6), transparent);
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), transparent);
}

.hero-bottom-border:before {
	content: "";
	height: 10px;
	width: 100%;
	position: absolute;
	top: 490px;
	background-color: #f8f8f8;
	opacity: 0.2;
}

.hero-home h1 {
	margin: 0;
	font-size: 60px;
	font-weight: 700;
	text-transform: uppercase;
	color: #fff;
	text-shadow: #000 3px 2px;
}
.hero-home p {
	text-shadow: #000 1px 2px;
}
.main-title h2 {
	font-size: 26px;
	margin: 30px 0 0 0;
}

.main-title p {
	font-size: 18px;
	margin-top: 0;
}

.main-title span {
	width: 120px;
	height: 2px;
	background-color: #ccc;
	display: block;
}

.main-title span em {
	width: 60px;
	height: 2px;
	background-color: #f25c04;
	display: block;
	margin-top: 30px;
}

.order .main-title span em {
	width: 60px;
	height: 2px;
	background-color: #f25c04;
	display: block;
	margin-top: 0;
}

.order .main-title h2 {
	font-size: 26px;
	margin: 0 0 30px 0;
}

/* Hero Buttons */

a.btn-1,
.btn-1 {
	border: none;
	color: #f25c04;
	background: #fff;
	cursor: pointer;
	padding: 7px 20px;
	display: inline-block;
	outline: none;
	font-size: 14px;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-webkit-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-ms-border-radius: 5px;
	border-radius: 5px;
	font-weight: 500;
	text-shadow: none;
	box-shadow: 0px 0px 0px 7px rgb(255 255 255 / 20%);
}

a.btn-1:hover,
.btn-1:hover {
	background-color: #f25c04;
	color: #fff;
}

a.btn-1.medium,
.btn-1.medium {
	padding: 12px 45px;
	font-size: 16px;
}

a.btn-2,
.btn-2 {
	border: none;
	color: #fff;
	background: #f25c04;
	cursor: pointer;
	padding: 7px 20px;
	display: inline-block;
	outline: none;
	font-size: 14px;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-webkit-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-ms-border-radius: 5px;
	border-radius: 5px;
	font-weight: 500;
	text-shadow: none;
}

a.btn-2:hover,
.btn-2:hover {
	background-color: #121921;
	color: #fff;
}

/* Mouse */

.mouse-frame {
	height: 74px;
	width: 74px;
	border-radius: 50%;
	position: absolute;
	top: 460px;
	left: calc(50% - 37px);
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #f8f8f8;
}

.mouse-frame .mouse {
	position: relative;
	height: 35px;
	width: 25px;
	border: solid 2px #f25c04;
	border-radius: 20px;
}

.mouse-frame .mouse:before {
	content: "";
	height: 3px;
	width: 3px;
	border-radius: 50%;
	background-color: #f25c04;
	position: absolute;
	top: 7px;
	left: 11px;
	-webkit-animation: scroll 1s infinite;
	animation: scroll 1s infinite;
}

.mouse-frame:after {
	-webkit-transform: scaleX(1.25) scaleY(1.09);
	transform: scaleX(1.25) scaleY(1.09);
	content: "";
	width: 74px;
	height: 37px;
	opacity: 0.15;
	border-radius: 1000px 1000px 0 0;
	position: absolute;
	top: -11.6%;
	background-color: #f8f8f8;
}

@-webkit-keyframes scroll {
	0% {
		-webkit-transform: translateY(0) translateX(-50%);
		transform: translateY(0) translateX(-50%);
	}
	50% {
		-webkit-transform: translateY(6px) translateX(-50%);
		transform: translateY(6px) translateX(-50%);
	}
	100% {
		-webkit-transform: translateY(0) translateX(-50%);
		transform: translateY(0) translateX(-50%);
	}
}

@keyframes scroll {
	0% {
		-webkit-transform: translateY(0) translateX(-50%);
		transform: translateY(0) translateX(-50%);
	}
	50% {
		-webkit-transform: translateY(6px) translateX(-50%);
		transform: translateY(6px) translateX(-50%);
	}
	100% {
		-webkit-transform: translateY(0) translateX(-50%);
		transform: translateY(0) translateX(-50%);
	}
}

/* Services
==================================== */
.box,
.boxMain,
.boxMainWithImage,
.planSelection {
	position: relative;
	text-align: center;
	background: #fff;
	padding: 60px 30px 30px 30px;
	-webkit-box-shadow: 0 2px 2px 0 rgb(9 30 66 / 13%);
	-moz-box-shadow: 0 2px 2px 0 rgb(9 30 66 / 13%);
	box-shadow: 0 2px 2px 0 rgb(9 30 66 / 13%);
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-ms-border-radius: 5px;
	border: 1px solid #ccc;
	border-radius: 5px;
	color: #555;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-webkit-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}
.box,
.boxMain,
.planSelection {
	margin-bottom: 30px;
}
.planSelection {
	justify-content: space-between;
}

.box p,
.boxMain p,
.boxMainWithImage p {
	font-weight: 400;
	margin-top: 5px;
}

.boxMain {
	height: 25rem;
}
.boxMain h3 {
	font-size: 22px;
	margin: 0 0 10px;
	text-transform: uppercase;
	letter-spacing: 1px;
	color: #333;
}
.boxMain .cta-button {
	position: absolute;
	bottom: 20%;
	left: 50%;
	transform: translateX(-50%);
}
.box:hover,
.boxMain:hover {
	-webkit-transform: translateY(-10px);
	-moz-transform: translateY(-10px);
	-ms-transform: translateY(-10px);
	-o-transform: translateY(-10px);
	transform: translateY(-10px);
}

.box .icon {
	height: 2.8rem;
	margin: 0 auto;
	margin-bottom: 1rem;
	font-size: 3.5rem;
	color: #f25c04;
}

.service-title,
.contact-title {
	font-size: 18px;
	margin-bottom: 0;
}

/* Banner
==================================== */

.banner {
	padding-bottom: 60px;
}

.banner .content {
	background: url(../img/bg/bg-banner.jpg) no-repeat center center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-ms-border-radius: 5px;
	border-radius: 5px;
	overflow: hidden;
	width: 100%;
	height: 350px;
	position: relative;
}

.banner .content h2 {
	color: #fff;
	font-size: 26px;
	margin: 0;
	margin-top: 10px;
}

.banner .textbox {
	padding: 90px;
	height: 100%;
}

.banner .content small {
	color: #fff;
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 500;
	margin: 0;
}

.banner .content p {
	color: #fff;
	font-size: 21px;
	margin: 0;
	margin-bottom: 20px;
}

.banner .mask {
	background-color: rgba(0, 0, 0, 0.3);
	width: 100%;
	height: 100%;
}

/* Faq
==================================== */

.faq h3 {
	font-size: 20px;
	margin: 10px 0;
}

.faq-accordion {
	margin-top: 30px;
	margin-bottom: 0px;
}

.faq-accordion .card {
	border: 0;
	margin-bottom: 5px;
}

.faq-accordion .card:last-child {
	margin-bottom: 30px;
}

.faq-accordion .card .card-header {
	background-color: #fff;
	border: 1px solid #ccc;
	padding: 0;
	border-radius: 0;
}

.faq-accordion .item-body {
	padding-bottom: 0;
	border-left: 1px solid #ccc;
	border-right: 1px solid #ccc;
	border-bottom: 1px solid #ccc;
}

.faq-accordion .card-header h4 {
	font-size: 16px;
}

.faq-accordion .card-header h4 a {
	border: 0;
	display: block;
	color: #333;
	padding: 20px;
}

.faq-accordion .card-header h4 a i.indicator {
	font-size: 18px;
	font-size: 1.125rem;
	font-weight: normal;
	float: right;
	color: #f25c04;
}

.card {
	border-radius: 0;
	border: 0;
	margin-bottom: 5px;
}

.card-body {
	border-left: 1px solid #ccc;
	border-right: 1px solid #ccc;
	border-bottom: 1px solid #ccc;
}

.card-body p {
	font-size: 14px;
	font-weight: 400;
	margin: 0 0 20px 0;
}

.card-body p:last-child {
	margin: 0;
}

.faq .main-title span {
	width: 100px;
}

.faq .main-title span em {
	margin-top: 0;
	width: 50px;
}

/* Ribbon Size */

.ribbon-size {
	background-color: #f25c04;
	color: #fff;
	display: inline-block;
	position: absolute;
	bottom: 15px;
	right: 15px;
	font-weight: 400;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	-ms-border-radius: 3px;
	border-radius: 3px;
	padding: 5px 10px;
	line-height: 1;
	font-size: 13px;
	z-index: 2;
}

/* Form Elements
==================================== */

/* Ribbon Discount */

.ribbon-discount {
	position: absolute;
	right: 0;
	top: 0;
	z-index: 2;
	overflow: hidden;
	width: 75px;
	height: 75px;
	text-align: right;
}

.ribbon-discount span,
.ribbon-discount-main span {
	font-size: 13px;
	font-weight: 400;
	font-style: normal;
	color: #fff;
	text-align: center;
	line-height: 20px;
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
	width: 100px;
	display: block;
	background: #f25c04;
	-webkit-box-shadow: 0 6px 10px -5px #000;
	-moz-box-shadow: 0 6px 10px -5px #000;
	box-shadow: 0 6px 10px -5px #000;
	text-shadow: 1px 1px 2px rgb(0 0 0 / 25%);
	position: absolute;
	top: 19px;
	right: -21px;
}

/* Terms Link */

.terms-link,
.modify-order,
.terms-link:visited,
.modify-order:visited {
	text-decoration: none;
	color: #f25c04;
	margin-left: 5px;
	transition: none;
}

.terms-link:hover,
.modify-order:hover {
	color: #f25c04;
	text-decoration: underline;
}
/* Back To Top
==================================== */

#toTop {
	width: 40px;
	height: 40px;
	background-color: #f25c04;
	text-align: center;
	padding: 10px;
	line-height: 20px;
	position: fixed;
	bottom: 15px;
	right: 15px;
	cursor: pointer;
	display: none;
	color: #fff;
	font-size: 18px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-ms-border-radius: 5px;
	border-radius: 5px;
	z-index: 2;
}

/* Responsive
==================================== */

@media (max-width: 575px) {
	.hero-home .content h1 {
		font-size: 48px;
	}
	.sticky-nav a.list-group-item {
		font-size: 13px;
	}
	/* Form Elements
	==================================== */
	#search {
		margin-top: 15px;
	}
}

@media (max-width: 767px) {
	/* Hero
	==================================== */
	.hero-home {
		height: 450px;
	}
	.hero-home.bg-mockup {
		height: 450px;
	}
	.hero-home .content {
		font-size: 16px;
		padding: 0 15px;
	}
	.hero-home .content h3 {
		font-size: 42px;
	}
	/* Mouse */
	.mouse-frame {
		top: 420px;
	}
	.tst-content-frame:before {
		top: 440px;
	}
	/* Hero Order */
	.hero-order .review-wrap {
		margin-top: 30px;
	}
	.hero-order a.review-link {
		position: static;
	}
	.hero-order .score span {
		font-size: 18px;
		font-weight: 500;
		float: right;
		text-align: left;
	}
	.order .main-title h2 {
		font-size: 26px;
		margin: 0 0 15px 0;
	}
	/* Sticky Nav */
	.sticky-nav {
		padding: 15px 0;
	}
	.sticky-nav ul {
		overflow: hidden;
		overflow-x: auto;
		white-space: nowrap;
		padding: 6px 0;
		text-align: center;
	}
	.sticky-nav ul li {
		margin-right: 5px;
	}
	/* Banner
	==================================== */
	.banner .textbox {
		padding: 60px;
		text-align: center;
	}
	/* Footer
	==================================== */
	footer #copy {
		text-align: left;
		margin-bottom: 25px;
	}
	footer ul li {
		margin-bottom: 10px;
	}
}

@media (max-width: 991px) {
	/* Header
	==================================== */

	header .container {
		width: 100% !important;
		margin-right: auto !important;
		margin-left: auto !important;
		padding-right: 15px !important;
		padding-left: 15px !important;
		width: 100% !important;
		max-width: 100%;
	}
	/* Header Menu Chevron Down */
	.main-menu a i {
		display: none;
	}
	/* Header Icons */
	ul#menuIcons {
		right: 0;
		top: 2px;
	}
	/* Logo */
	#logo {
		position: relative;
		left: 45px;
	}
	/* Banner
	==================================== */
	.banner {
		margin: 0 0 15px 0;
		padding-bottom: 0;
	}
	/* Sub Header
	==================================== */
	.sub-header {
		margin-top: 0;
	}
	/* Footer
	==================================== */
	footer.main-footer {
		padding-top: 60px;
		padding-bottom: 60px;
	}
	/* Containers
	==================================== */
	#orderContainer,
	#personalDetails {
		margin-bottom: 15px;
	}
	.services,
	.contacts {
		padding: 0;
	}
	.faq,
	.order {
		padding: 15px 0 0 0;
	}
	.faq-accordion,
	.box,
	.filter-box,
	.contact-box {
		margin: 0 0 15px 0;
	}
	/* Grid */
	.item-body {
		margin-bottom: 15px;
	}
	/* Form Elements
	==================================== */
	.price-box {
		top: 26px;
	}
	/* Faq
	==================================== */
	.faq .main-title h2 {
		margin: 15px 0;
	}
	.faq-accordion .card:last-child {
		margin-bottom: 0;
	}
}

@media (min-width: 575px) and (max-width: 991px) {
	/* Containers
	==================================== */
	/* Order Items */
	.order-list-img {
		width: 100px;
	}
}
